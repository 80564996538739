<template>
  <div class="mv">
    mv
  </div>
</template>

<script>
export default {
  name: 'Mv',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.mv {
}
</style>